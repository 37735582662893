import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const mediaQuery = window?.matchMedia(query);
  const [match, setMatch] = useState<boolean>(mediaQuery && !!mediaQuery.matches);
  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setMatch(mediaQuery && !!e.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, [mediaQuery]);

  return match;
};

export default useMediaQuery;
