import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { throttle } from 'lodash';
import cn from 'classnames';
import styles from './styles.module.scss';

type Coordinates = {
  x: number,
  y: number,
}

type Boundaries = {
  top: number,
  right: number,
  bottom: number,
  left: number,
}

// eslint-disable-next-line no-shadow
enum RingStates {
  FAR = 'far',
  NEAR = 'near',
  INSIDE = 'inside',
  COMPLETE = 'complete',
}

const BALL_SIZE = Number(styles.size);
const NEAR_DISTANCE = BALL_SIZE / 2;
const PULSE_DURATION = Number(styles.pulseDuration);
const THROTTLE_DURATION = 50;
const MAXIMUM_MAGNET_TRANSLATION_PERCENTAGE = 20;

const getNearBoundaries = (boundaries: Boundaries): Boundaries => ({
  top: boundaries.top - NEAR_DISTANCE,
  right: boundaries.right + NEAR_DISTANCE,
  bottom: boundaries.bottom + NEAR_DISTANCE,
  left: boundaries.left - NEAR_DISTANCE,
});

const coordinatesInBoundaries = (
  coordinates: Coordinates,
  boundaries: Boundaries,
): boolean => (
  coordinates.x >= boundaries.left
    && coordinates.x <= boundaries.right
    && coordinates.y >= boundaries.top
    && coordinates.y <= boundaries.bottom
);

type PropsTypes = {
  children?: React.ReactNode
}

export function MagneticBall({ children }: PropsTypes): JSX.Element {
  const [outerRingState, setOuterRingState] = useState<RingStates>(RingStates.FAR);
  const [innerRingState, setInnerRingState] = useState<RingStates>(RingStates.FAR);
  const [isRingsPulsing, setIsRingsPulsing] = useState<boolean>(false);
  const outerRingRef = useRef<HTMLDivElement>(null);
  const innerRingRef = useRef<HTMLDivElement>(null);
  const cursorLinkRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const calculateActiveRings = throttle((ev: MouseEvent): void => {
    if (
      !outerRingRef.current
      || !innerRingRef.current
      || !cursorLinkRef.current
    ) return;

    function resetStyles(): void {
      innerRingRef.current!.style.transform = 'translate(0%, 0%)';
      cursorLinkRef.current!.style.width = '0';
    }

    const coordinates = {
      x: ev.x,
      y: ev.y,
    };

    const innerBoundaries = innerRingRef.current.getBoundingClientRect();
    const outerBoundaries = outerRingRef.current.getBoundingClientRect();
    const nearOuterBoundaries = getNearBoundaries(outerBoundaries);

    if (coordinatesInBoundaries(coordinates, innerBoundaries)) {
      setInnerRingState(RingStates.INSIDE);
      setOuterRingState(RingStates.COMPLETE);
      setIsRingsPulsing(true);
      resetStyles();
      return;
    }

    if (coordinatesInBoundaries(coordinates, outerBoundaries)) {
      setInnerRingState(RingStates.NEAR);
      setOuterRingState(RingStates.INSIDE);
      setIsRingsPulsing(true);

      // use outer ring to keep the center constant
      const ringCenter = {
        x: outerBoundaries.left + (outerBoundaries.width / 2),
        y: outerBoundaries.top + (outerBoundaries.height / 2),
      };

      const ringDistance = {
        x: coordinates.x - ringCenter.x,
        y: coordinates.y - ringCenter.y,
      };

      // Calculate inner ring translation
      const translatePercentage = {
        x: (ringDistance.x / BALL_SIZE) * MAXIMUM_MAGNET_TRANSLATION_PERCENTAGE,
        y: (ringDistance.y / BALL_SIZE) * MAXIMUM_MAGNET_TRANSLATION_PERCENTAGE,
      };
      innerRingRef.current.style.transform = `translate(${translatePercentage.x}%, ${translatePercentage.y}%)`;

      // Calculate cursor link width and rotation
      const cursorLinkInfo = {
        length: Math.sqrt((ringDistance.x ** 2) + (ringDistance.y ** 2)),
        angle: (Math.atan2(ringDistance.y, ringDistance.x) / Math.PI) * 180,
      };
      cursorLinkRef.current.style.width = `${cursorLinkInfo.length}px`;
      cursorLinkRef.current.style.transform = `rotate(${cursorLinkInfo.angle}deg)`;

      return;
    }

    if (coordinatesInBoundaries(coordinates, nearOuterBoundaries)) {
      setInnerRingState(RingStates.FAR);
      setOuterRingState(RingStates.NEAR);
      setIsRingsPulsing(true);
      resetStyles();
      return;
    }

    clearInterval(intervalRef.current!);
    setInnerRingState(RingStates.FAR);
    setOuterRingState(RingStates.FAR);
    setIsRingsPulsing(false);
    resetStyles();
  }, THROTTLE_DURATION);

  useEffect(() => {
    if (outerRingState !== RingStates.FAR) {
      intervalRef.current = setTimeout(() => {
        setIsRingsPulsing(!isRingsPulsing);
        clearTimeout(intervalRef.current!);
      }, PULSE_DURATION);
    }

    return () => clearTimeout(intervalRef.current!);
  }, [isRingsPulsing, outerRingState]);

  useLayoutEffect(() => {
    window.addEventListener('mousemove', calculateActiveRings);

    return () => {
      window.removeEventListener('mousemove', calculateActiveRings);
    };
  }, [calculateActiveRings]);

  return (
    <div
      ref={outerRingRef}
      className={cn(
        styles.outerRing,
        styles[`outerRing--${outerRingState}`],
        { [styles[`outerRing--${outerRingState}-pulse`]]: isRingsPulsing },
      )}
    >
      <div
        ref={cursorLinkRef}
        className={cn(
          styles.cursorLink,
          { [styles['cursorLink--pulse']]: isRingsPulsing },
        )}
      />
      <div
        ref={innerRingRef}
        className={cn(
          styles.innerRing,
          styles[`innerRing--${innerRingState}`],
          { [styles[`innerRing--${innerRingState}-pulse`]]: isRingsPulsing },
        )}
      >
        { children }
      </div>
    </div>
  );
}

MagneticBall.defaultProps = {
  children: undefined,
};

export default MagneticBall;
