import React from 'react';
import { chunk } from 'lodash';
import cn from 'classnames';
import { uid } from 'react-uid';
import VideoBackground from '../../components/VideoBackground';
import MagneticBall from '../../components/MagneticBall';

import linkedinIcon from '../../assets/icons/linkedin.svg';
import githubIcon from '../../assets/icons/github.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import emailIcon from '../../assets/icons/email.svg';

import styles from './styles.module.scss';

const linksChunks = chunk([
  {
    label: 'Linkedin',
    icon: linkedinIcon,
    link: 'https://www.linkedin.com/in/ahmedrafik/',
  },
  {
    label: 'Github',
    icon: githubIcon,
    link: 'https://github.com/armaaar',
  },
  {
    label: 'Twitter',
    icon: twitterIcon,
    link: 'https://twitter.com/Armaaar',
  },
  {
    label: 'Email',
    icon: emailIcon,
    link: 'mailto:mail@ahmedrafik.me',
  },
], 2);

function HomePage(): JSX.Element {
  return (
    <div className={styles.centeredPage}>
      <VideoBackground />
      <div className={styles.section}>
        <div className={styles.content}>
          <h1 className={styles.headline}>Ahmed Rafik Ibrahim</h1>
          <p className={styles.headlineInfo}>Full stack web developer</p>
          <p className={styles.paragraph}>
            I&apos;m a +7 years Full stack web developer
            I developed my skills by working on production projects.
            I always desire to be associated with an organization
            that gives me scope to apply my knowledge,
            skills and innovative ideas at the work to be performed.
          </p>
          <p className={styles.paragraph}>
            Aside from that, I&apos;m a simple honest person
            who enjoys long walks and deep conversations.
            Usually, you would find me listening to Jazz, country, and blues,
            and sharing memes constantly.
          </p>
          <h2 className={styles.subHeadline}>Let&apos;s get in touch</h2>
        </div>
        {linksChunks.map((links, index) => (
          <div
            key={uid(links)}
            className={cn(
              styles.socialSection,
              styles[`socialSection--${index + 1}`],
            )}
          >
            {links.map(({ label, icon, link }) => (
              <MagneticBall key={label}>
                <a className={styles.stretchLink} href={link} target="_blank" rel="noreferrer">
                  <img className={styles.icon} alt={label} title={label} src={icon} />
                </a>
              </MagneticBall>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
