import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';

import breakpoints from '../../styles/modules/breakpoints.module.scss';
import styles from './styles.module.scss';

import background from '../../assets/space-bg.png';
import backgroundMobile from '../../assets/space-bg-mobile.png';

import videoHD from '../../assets/space-loop-hd.mp4';
import videoSD from '../../assets/space-loop-sd.mp4';

function VideoBackground(): JSX.Element {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.md}px)`);
  const videoPath = isMobile ? videoSD : videoHD;
  const fallbackImagePath = isMobile ? backgroundMobile : background;

  return (
    <>
      <video autoPlay muted loop className={styles.backgroundVideo} src={videoPath}>
        <img className={styles.backgroundImage} src={fallbackImagePath} alt="space background" />
      </video>
      <div className={styles.overlay} />
    </>
  );
}

export default VideoBackground;
